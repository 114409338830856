"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.ChainAdapterDisplayName = exports.ValidAddressResultType = exports.FeeDataKey = exports.CONTRACT_INTERACTION = void 0;
exports.CONTRACT_INTERACTION = 'contract-interaction';
var FeeDataKey;
(function (FeeDataKey) {
    FeeDataKey["Slow"] = "slow";
    FeeDataKey["Average"] = "average";
    FeeDataKey["Fast"] = "fast";
})(FeeDataKey || (exports.FeeDataKey = FeeDataKey = {}));
var ValidAddressResultType;
(function (ValidAddressResultType) {
    ValidAddressResultType["Valid"] = "valid";
    ValidAddressResultType["Invalid"] = "invalid";
})(ValidAddressResultType || (exports.ValidAddressResultType = ValidAddressResultType = {}));
var ChainAdapterDisplayName;
(function (ChainAdapterDisplayName) {
    ChainAdapterDisplayName["Thorchain"] = "THORChain";
    ChainAdapterDisplayName["Ethereum"] = "Ethereum";
    ChainAdapterDisplayName["Avalanche"] = "Avalanche C-Chain";
    ChainAdapterDisplayName["Optimism"] = "Optimism";
    ChainAdapterDisplayName["BnbSmartChain"] = "BNB Smart Chain";
    ChainAdapterDisplayName["Polygon"] = "Polygon";
    ChainAdapterDisplayName["Gnosis"] = "Gnosis";
    ChainAdapterDisplayName["Arbitrum"] = "Arbitrum One";
    ChainAdapterDisplayName["ArbitrumNova"] = "Arbitrum Nova";
    ChainAdapterDisplayName["Base"] = "Base";
    ChainAdapterDisplayName["Cosmos"] = "Cosmos";
    ChainAdapterDisplayName["Bitcoin"] = "Bitcoin";
    ChainAdapterDisplayName["BitcoinCash"] = "Bitcoin Cash";
    ChainAdapterDisplayName["Dogecoin"] = "Dogecoin";
    ChainAdapterDisplayName["Litecoin"] = "Litecoin";
})(ChainAdapterDisplayName || (exports.ChainAdapterDisplayName = ChainAdapterDisplayName = {}));
